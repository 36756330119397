import EditableCard from "components/EditableCard";
import { collection, addDoc } from "firebase/firestore";
import { useFirestore, useFirestoreCollection } from "reactfire";

export default function Home() {
    document.title = "Home"
    const db = useFirestore()
    const interestsCollection = collection(db, "interests")
    const projectsCollection = collection(db, "projects")

    const { data: interests } = useFirestoreCollection(interestsCollection)
    const { data: projects } = useFirestoreCollection(projectsCollection)

    return (
        <>
            <h1 id="home">Home</h1>
            <p><a href="#interests">interests</a> <a href="#projects">projects</a></p>
            <h2 id="interests">Interests</h2>
            <p><a href="#home">up</a></p>
            <button onClick={e => {addDoc(interestsCollection, {name: "new", description: "blank", order: 0})}}>add</button>
            <div style={{display: "grid", gridTemplate: "auto/1fr 1fr", gap: "30px"}}>
                {interests && interests.docs.sort((a, b) => { return a.data().order - b.data().order }).map(interest => {
                    return <EditableCard card={interest} collection="interests" key={interest.id} />
                })}
            </div>
            <h2 id="projects">Projects</h2>
            <p><a href="#home">up</a></p>
            <button onClick={e => {addDoc(projectsCollection, {name: "new", description: "blank", order: 0})}}>add</button>
            <div style={{display: "grid", gridTemplate: "auto/1fr 1fr", gap: "30px"}}>
                {projects && projects.docs.sort((a, b) => { return a.data().order - b.data().order }).map(project => {
                    return <EditableCard card={project} collection="projects" key={project.id} />
                })}
            </div>
        </>
    )
}