import { useEffect, useState } from "react"
import { useStorage, useStorageDownloadURL } from "reactfire"
import { updateDoc, deleteField, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import './style.css'

export default function EditableCard({ card, collection, updateCard, updateImg }) {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [link, setLink] = useState("")
    const [linkEnabled, setLinkEnabled] = useState(false)
    const [order, setOrder] = useState(0)
    const [img, setImg] = useState("")
    const [imgEnabled, setImgEnabled] = useState(false)
    const [preview, setPreview] = useState()
    const [previewUrl, setPreviewUrl] = useState()

    const storage = useStorage()

    useEffect(() => {
        setName(card.data().name)
        setDescription(card.data().description)
        setLink(card.data().link || "")
        setLinkEnabled(!!card.data().link)
        setOrder(card.data().order)
        setImg(card.data().img || "")
        setImgEnabled(!!card.data().img)
    }, [card])

    useEffect(() => {
        if (preview) {
            const fileReader = new FileReader()

            fileReader.addEventListener("load", () => {
                setPreviewUrl(fileReader.result)
            }, false)
            fileReader.readAsDataURL(preview)
        }
    }, [preview])

    /**
     * 
     * @param {import("react").SyntheticEvent} e 
     */
    function handleSubmit(e) {
        e.preventDefault()

        const update = {
            name,
            description,
            order,
            link: linkEnabled? link: deleteField()
        }
        updateDoc(card.ref, update)
    }

    function handlePicSubmit(e) {
        e.preventDefault()

        const oldImgRef = card.data().img && ref(storage, collection + "/" + card.data().img)
        const newImgRef = ref(storage, collection + "/" + img)

        if (!imgEnabled) {
            oldImgRef && deleteObject(oldImgRef)
            updateDoc(card.ref, {
                img: deleteField()
            })
        } else {
            if(card.data().img && card.data().img !== img) {
                deleteObject(oldImgRef)
            }
            uploadBytes(newImgRef, preview)
            updateDoc(card.ref, {
                img
            })
        }
    }

    return (
        <div style={{
            background: "#A7A7A7A7",
            padding: "30px 35px",
            borderRadius: "15px",
            display: "grid",
            gap: "10px"
        }}>
            <form onSubmit={handlePicSubmit} style={{border: "solid 1px black", display: "grid", gap: "10px", padding: "10px"}}>
                <div style={{ width: "70%", height: "200px", margin: "auto" }}>
                    {previewUrl ? <img height="200" alt="" src={previewUrl} style={{ width: "100%", objectFit: "cover" }} /> :
                        card.data().img && <ImgComp collection={collection} img={card.data().img} />
                    }
                </div>
                <div>
                    <label>Image: </label>
                    <input required={imgEnabled} type="file" accept="image/*" onChange={e => { setPreview(e.target.files[0]); setImg(e.target.files[0].name); setImgEnabled(true) }} />
                </div>
                <div>
                    <button onClick={(e) => {e.preventDefault(); setImgEnabled(!imgEnabled) }}>{imgEnabled ? "disable" : "enable"}</button>
                    <label htmlFor="img"> Image: </label>
                    <input required={imgEnabled} disabled={!imgEnabled} type="text" value={img} name="img" onChange={e => { setImg(e.target.value) }} onFocus={e => { e.target.classList.add("first") }} />
                </div>
                <button type="submit">Update Image</button>
            </form>
            <form onSubmit={handleSubmit} style={{ display: "grid", gap: "20px", border: "1px solid black", padding: "10px"}} >
                <div>
                    <label htmlFor="name" >Name*: </label>
                    <input style={{fontSize: "1.5rem"}} required type="text" name="name" value={name} onChange={e => { setName(e.target.value) }} onFocus={e => { e.target.classList.add("first") }} />
                </div>
                <div>
                    <label htmlFor="order" >Order*: </label>
                    <input required type="text" name="order" value={order} onChange={e => { setOrder(e.target.value) }} onFocus={e => { e.target.classList.add("first") }} />
                </div>
                <div>
                    <label htmlFor="description">Description*: </label>
                    <textarea required style={{ width: "98%", display: "block", height: "300px", fontSize: "1rem" }} value={description} name="description" onChange={e => { setDescription(e.target.value) }} onFocus={e => { e.target.classList.add("first") }} />
                </div>
                <div>
                    <button onClick={() => { setLinkEnabled(!linkEnabled) }}>{linkEnabled ? "disable" : "enable"}</button>
                    <label htmlFor="link"> Link: </label>
                    <input required={linkEnabled} disabled={!linkEnabled} type="text" value={link} name="link" onChange={e => { setLink(e.target.value) }} />
                </div>
                <button type="submit">Update Details</button>
            </form>
            <button onClick={e => {
                deleteDoc(card.ref)
                const oldImgRef = card.data().img && ref(storage, collection + "/" + card.data().img)
                oldImgRef && deleteObject(oldImgRef)
            }}>delete</button>
        </div>
    )
}

function ImgComp({ collection, img }) {
    const storage = useStorage()

    const imgRef = ref(storage, collection + "/" + img)

    const { data: imageLink } = useStorageDownloadURL(imgRef)

    return (
        <img height="200" alt="" src={imageLink} style={{ width: "100%", objectFit: "cover" }} />
    )
}