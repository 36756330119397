
import { Outlet, Route, Routes } from "react-router-dom";

// Adding providers for react router dom to use throughout the app.
import { BrowserRouter } from "react-router-dom";
import Login from 'routes/Login';
import Home from 'routes/Home';
import RequireAuth from 'routes/RequireAuth';

/**
 * We will use this as the base for our routes only and nothing else all layout
 * should be done with nested routes instead
 */
function App() {

  return (
    <div id='app'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <RequireAuth>
              <div style={{width: "80vw", margin: "0 auto"}}>
                <Outlet/>
              </div>
            </RequireAuth>
          }>
            <Route
              index // Equivalent to saying the page content for '/'
              element={<Home />}
            />
            <Route
              path='*' // Providing a 404 page for '/' and thus the whole site.
              element={
                <>
                  <h1>404 Not Found</h1>
                  <p>You'll have to journey elsewhere.</p>
                </>
              }
            />
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
